@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'aos/dist/aos.css';

/* Add these styles to your global CSS */
@media print {
    @page {
      size: A4;
      margin-left: -0.1cm;
      margin-right: -0.1cm;
    }
  
    body {
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
    }
  
    .no-print {
      display: none !important;
    }
  
    #report-content {
      padding: 0 !important;
    }
  
    .motion-section {
      break-inside: avoid;
      page-break-inside: avoid;
    }
  }